/* pagination */
.paginationContainer {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding-left: 0;
  list-style: none;
}


.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}



.fullScreenloader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  transition: .3s ease-out;
  background-color: #fff;
  z-index: 80;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animateLogo {
  animation: logoRotate .8s ease-in-out;
  animation-iteration-count: infinite;
}


  
/* media queries  */
@media screen and (max-width: 550px) {
  .paginationContainer {
    width: 100%;
  }
}


@keyframes logoRotate {
  from {
    transform: rotate(10deg);
  }

  to {
    transform: rotate(360deg);
  }
}