:root {
    --primary: #232A44;
    --secondary: #FFD600;
    --success: #06C167;
    --successDark: #024022;
    --danger: #FC7F79;
    --dangerDark: #DE1135;
}


.controllerAnime {
    animation: controllerAnime 1s ease-in-out;
}

.pulse {
    animation: pulsate 1s ease-in-out infinite;
}

.qpAnime {
    animation: qpAnime 1s ease-in-out;
}

.featureDiceAnime {
    animation: diceAnime 1s ease-out;
}


.animateLogo {
    animation: logoRotate .8s ease-in-out infinite;
}
  

.iconBg-success {
    background-color: rgba(6, 193, 103, 0.3);
}

.iconBg-danger {
    background-color: rgba(255, 225, 222, 0.3);
}

.iconBg-secondary {
    background-color: var(--secondary);
}


.vScroll::-webkit-scrollbar{
    width: 5px;
}
  
.hScroll::-webkit-scrollbar{
    height: 5px;
}
  
.vScroll::-webkit-scrollbar-track, .hScroll::-webkit-scrollbar-track{
    background: #F6F5F2;
}

.vScroll::-webkit-scrollbar-thumb, .hScroll::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 5px;
}

.slick-slide > div {
    margin: 0 10px;
}

.slick-list {
    margin: 0 -10px;
}

.balance-card {
    animation: walletCardAnime .5s ease-out;
}

/* game status dot */
.game-active, .game-live, .game-complete, .ticket-won {
    color: var(--success) !important;
}
  
.game-pending, .ticket-pending, .game-draft, .game-drafts {
    color: var(--secondary) !important;
}
  
.game-declined, .game-cancelled, .game-failed, .game-expired, .ticket-lost {
    color: var(--dangerDark) !important;
}


.mb20 {
    margin-bottom: 20px !important;
}

.activeUserItem {
    animation: glimmer 2.5s ease-out infinite;
}

.hide-hubspot-chat {
    visibility: hidden !important;
}

/* keyframes */
@keyframes glimmer {
    from {
        background-color: rgba(218, 225, 232, 0.4);
    }
    
    to {
        background-color: rgba(218, 225, 232, 0.3);
    }
}

@keyframes walletCardAnime {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    
    to {
        opacity: 1;
        transform: translateX(0);
    }
}  

@keyframes qpAnime {
    10% {
        font-size: 20px;
    }
    20% {
        font-size: 12px;
    }
    40% {
        font-size: 20px;
    }
    60% {
        font-size: 12px;
    }
    80% {
        font-size: 20px;
    }
    100% {
        font-size: 16px;
    }
}

@keyframes diceAnime {
    from {
        transform: rotate("10deg");
    }

    to {
        transform: rotate("180deg");
    }
}


@keyframes logoRotate {
    from {
      transform: rotate(10deg);
    }
  
    to {
      transform: rotate(360deg);
    }
}

@keyframes pulsate {
    from {
        transform: scale(1.2);
    }
    to {
        transform: scale(1.1);
    }
}

@keyframes controllerAnime {
    10% {
        transform: scale(1.4);
    }
    25% {
        transform: rotate(10deg) scale(1.2);
    }
    35% {
        transform: rotate(0deg) scale(1.2);
    }
    45% {
        transform: rotate(10deg) scale(1.2);
    }
    55% {
        transform: rotate(0deg) scale(1.2);
    }
    65% {
        transform: rotate(10deg) scale(1.2);
    }
    75% {
        transform: rotate(0deg) scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}