
.dateHeader {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin-top: 24px;
}

/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 24px;
}
  
/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    width: 2px;
    background-color: var(--primary);
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

.event {
    margin-bottom: 100px;
    position: relative;
    width: 50%;
}
  
/* The circles on the timeline */
.event::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: -3px;
    background-color: var(--primary);
    top: 0;
    border-radius: 50%;
    z-index: 1;
}

/* Fix the circle for events on the right side */
.right::after {
    left: -7px;
}

.left .eventDate, .right .eventDate {
    width: 50%;
    position: absolute;
    top: 0;
    right: -50%;
    z-index: 5;
    padding-inline: 27px;
}

.right .eventDate {
    right: 100%;
    text-align: right;
}

/* Container around content */
.container {
    padding: 10px 40px;
    padding-top: 0;
    background-color: inherit;
    position: relative;
}
  
/* Place the container to the left */
.left {
    left: 0;
}
  
/* Place the container to the right */
.right {
    left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left .arrow {
    position: absolute;
    height: 20px;
    top: 40%;
    transform: translateY(-50%);
    right: 23px;
    z-index: 1;
}


/* Add arrows to the right container (pointing left) */
.right .arrow {
    position: absolute;
    top: 40%;
    transform: translateY(-50%) rotate(180deg);
    z-index: 1;
    height: 20px;
    left: 23px;
}
  
/* The actual content */
.content {
    background-color: white;
    position: relative;
    padding: 16px;
    border-radius: 16px;
    box-shadow: 0px 0px 2px 0px #00000040;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content .icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.content .icon img {
    width: 24px;
    height: 24px;
}

.content .icon p {
    font-size: 20px;
}


.subtitle {
    font-size: 14px;
}

.date {
    font-size: 12px;
}
  
/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 800px) {
    
    .dateHeader {
        text-align: left;
        font-size: 22px;
    }

    /* Place the timelime to the left */
    .timeline::after {
        left: 12px;
    }
    
    /* Full-width containers */
    .event {
        width: 100%;
        margin-bottom: 20px;
    }

    
    .left .eventDate, .right .eventDate {
        position: unset;
        padding-left: 48px;
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
    }

    .container {
        padding-left: 45px;
        padding-right: 0;
    }
    
    /* Make sure that all arrows are pointing leftwards */
    .container .arrow {
        position: absolute;
        top: 40%;
        transform: translateY(-50%) rotate(180deg);
        z-index: 1;
        height: 20px;
        left: 28px;
    }

    /* Make sure all circles are at the same spot */
    .left::after, .right::after {
        left: 5px;
    }
    
    /* Make all right containers behave like the left ones */
    .right {
        left: 0%;
    }

    .content {
        padding: 12px;
    }

    .content .icon {
        width: 38px;
        height: 38px;
        margin-right: 6px;
    }

    .content .icon img {
        width: 20px;
        height: 20px;
    }

    .content .icon p {
        font-size: 18px;
    }

    .title, .figure {
        font-size: 14px;
    }

    .subtitle {
        font-size: 12px;
    }

    .date {
        font-size: 10px;
    }
}

@media screen and (max-width: 480px) {
    .container .arrow {
        left: 29px;
    }
    
}